<template>
    <v-layout wrap>
        <v-flex xs12 sm6>
            <v-switch v-model="item.isMEI" :label="`${$t('Possui CNPJ?')} (MEI)`" class="my-0 py-0 ml-4" />
        </v-flex>
        <v-flex xs12 sm6 v-if="item.isMEI">
            <v-text-field inputmode="numeric" v-model="item.cnpj" :rules="[cnpjRule, rules.cnpj, rules.cnpjValidatorDigit]" label="CNPJ" v-mask="'##.###.###/####-##'" />
        </v-flex>
        <v-flex xs12>
            <v-autocomplete v-model="item.schooling" :items="schoolings" :label="$t('Escolaridade')" :rules="[rules.required]" />
        </v-flex>
        <v-flex xs12>
            <Pix :item="item" :phone="item.phone" :cpf="item.cpf" />
        </v-flex>
    </v-layout>
</template>

<script>
import { mask } from "vue-the-mask";
import rules from "@/helpers/rules";
import schoolings from "@/assets/json/schoolings.json";
import Pix from "@/components/shared/Pix";

export default {
    components: { Pix },

    directives: { mask },

    props: {
        item: {
            type: Object,
            required: true,
        },
    },

    data() {
        return {
            rules,
            schoolings,
            cnpjRule: (value) => (!!value && this.item.isMEI) || !this.item.isMEI || this.$t("Obrigatório."),
        };
    },
};
</script>