<template>
    <v-layout wrap>
        <v-flex xs12 md6 offset-md3>
            <v-text-field inputmode="numeric" v-model="item.cep" :rules="[rules.cep]" :label="$t('CEP')" v-mask="'#####-###'" class="my-0 py-0" @input="getCEP" />
        </v-flex>

        <v-flex xs12 md6>
            <v-text-field v-model="item.city" :rules="[rules.required]" :label="$t('Cidade')" class="my-0 py-0" :disabled="item.cepOk" />
        </v-flex>

        <v-flex xs12 md6>
            <v-text-field v-model="item.state" :rules="[rules.required]" :label="$t('Estado')" class="my-0 py-0" :disabled="item.cepOk" />
        </v-flex>

        <v-flex xs12 md6>
            <v-text-field v-model="item.district" :rules="[rules.required]" :label="$t('Bairro')" class="my-0 py-0" :disabled="item.cepOk && hasDistrict" />
        </v-flex>

        <v-flex xs12 md6> </v-flex>

        <v-flex xs12 md5>
            <v-textarea v-model="item.address" :rules="[rules.required]" :label="$t('Endereço')" rows="3" class="my-0 py-0"></v-textarea>
        </v-flex>

        <v-flex xs12 md6 offset-md1>
            <v-text-field v-model="item.houseNumber" :label="$t('Número')" type="number" class="my-0 py-0" />
            <v-text-field v-model="item.complement" :label="$t('Complemento')" class="my-0 py-0" />
        </v-flex>
    </v-layout>
</template>

<script>
import { mask } from "vue-the-mask";
import axios from "axios";
import rules from "@/helpers/rules";

export default {
    directives: { mask },
    props: {
        item: {
            type: Object,
            required: true,
        },
    },

    data() {
        return {
            axios,
            rules,
            hasDistrict: false,
        };
    },

    methods: {
        getCEP() {
            if (/^\d{5}-\d{3}$/.test(this.item.cep)) {
                // this.axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
                this.axios
                    .get(`https://viacep.com.br/ws/${this.item.cep.replace("-", "")}/json/`)
                    .then((result) => {
                        if (result.data && result.data.uf && result.data.localidade) {
                            this.$nextTick(() => {
                                this.item.cepOk = true;
                                this.item.state = result.data.uf;
                                this.item.city = result.data.localidade;
                                this.item.district = result.data.bairro;
                                this.item.address = result.data.logradouro;
                                this.hasDistrict = this.item.district.length > 1;
                            });
                        }
                    })
                    .catch(() => {
                        this.item.cepOk = false;
                        this.hasDistrict = false;
                    });
            }
        },
    },
};
</script>