<template>
    <v-layout wrap>
        <v-flex xs12>
            <v-text-field :label="$t('Nome Completo')" v-model="nameCapitalize" :rules="[rules.required, rules.min(10)]" />
        </v-flex>
        <v-flex xs12 sm6>
            <v-text-field inputmode="numeric" v-model="item.cpf" :rules="[rules.required, rules.cpf, rules.cpfValidatorDigit]" label="CPF" v-mask="'###.###.###-##'" />
        </v-flex>
        <v-flex xs12 sm6>
            <v-text-field inputmode="numeric" :label="$t('Data de nascimento')" v-model="birthDate"
                :rules="[rules.required, rules.date, rules.rangeDate('DD/MM/YYYY', $moment().subtract({ year: 100 }), $moment())]" v-mask="'##/##/####'" />
        </v-flex>
        <v-flex xs12 sm6>
            <v-text-field type="tel" v-model="item.phone" :rules="[rules.required, rules.cellphone]" :label="$t('Celular')" v-mask="['(##) ####-####', '(##) #####-####']"
                required />
        </v-flex>
        <v-flex xs12>
            <v-text-field :label="$t('Nome da mãe')" v-model="motherNameCapitalize" :rules="[rules.required, rules.min(10)]" />
        </v-flex>
        <v-flex xs12>
            <v-autocomplete v-model="item.stateOfBirth" :filter="filterObject" :items="states" item-text="Nome" item-value="Sigla" :label="$t('Estado de nascimento')" />
        </v-flex>
    </v-layout>
</template>

<script>
import { mask } from "vue-the-mask";
import rules from "@/helpers/rules";
import states from "@/assets/json/states.json";

export default {
    directives: { mask },

    props: {
        item: {
            type: Object,
            required: true,
        },
    },

    data() {
        return {
            rules,
            states,
            activePicker: null,
            menu: false,
        };
    },

    methods: {
        filterObject(item, queryText, itemText) {
            itemText = itemText
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
                .toLowerCase();
            queryText = queryText
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
                .toLowerCase();

            return itemText.includes(queryText);
        },
    },

    computed: {
        birthDate: {
            get: function () {
                return this.item.birthDate ? this.$moment(this.item.birthDate).format("DD/MM/YYYY") : "";
            },
            set: function (data) {
                if (data && data.length == 10) {
                    this.item.birthDate = this.$moment(data, "DD/MM/YYYY");
                }
            },
        },
        motherNameCapitalize: {
            get: function () {
                return this.item.motherName;
            },
            set: function (data) {
                this.item.motherName = this.rules.filters.capitalized(data);
            },
        },
        nameCapitalize: {
            get: function () {
                return this.item.name;
            },
            set: function (data) {
                this.item.name = this.rules.filters.capitalized(data);
            },
        },
    },
};
</script>